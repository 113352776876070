.content-answer {
    .img-audio {
        height: 60px;
        cursor: pointer;
    }
}
.pointer-none {
    pointer-events: none;
}
#overlay-audio {
    .tooltip-inner {
        background-color: #fff;
        border: 2px solid #000;
        p {
            color: black;
            font-weight: 600;
        }
    }
}
.header-audio {
    &__text {
        position: absolute;
        z-index: 99;
        top: -65%;
        left: -35%;
    }
}
.show-answer {
    border: 2px solid #000;
    border-radius: 10px;
}
.show-result {
    border: 2px solid #000;
    border-radius: 50%;
    padding: 8px;
    font-size: 20px;
    width: 70px;
    height: 70px;
    color: #000;
    font-weight: 700;
}
.audioContainer {
    display: none;
}
.select > div:first-child{
  display: inline-block;
  width: fit-content;
}

.audio {
  top: 16px;
  left: 0;
  transform: translate(-50%, -50%);
}

.select {
  display: inline;
  margin: 0 0.5rem;
}

.your-choice {
  font-size: 18px !important;
  font-style: italic;

  .label {
    font-weight: bold;
    color: var(--orange);
  }
}
@font-face {
  font-family: "Quicksand-Bold";
  src: url("https://vysqy4zclvobj.vcdn.cloud/E_Learning/document/Quicksand-Bold.ttf")
    format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand-Medium";
  src: url("https://vysqy4zclvobj.vcdn.cloud/E_Learning/document/Quicksand-Medium.ttf")
    format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Monkey-appleberry";
  src: url("https://monkeymedia2020.s3.ap-southeast-1.amazonaws.com/upload%2Fweb%2Ffont_web%2FSVN-Appleberry.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Monkey-quicksand";
  src: url("https://hvegjijo7jobj.vcdn.cloud/upload/web/font_web/Quicksand-VariableFont_wght.ttf")
    format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "monkey-font-icon";
  src: url("https://monkeymedia2020.s3.ap-southeast-1.amazonaws.com/upload%2Fweb%2Ffont-icon%2Fmonkey-font-icon.eot");
  src: url("https://monkeymedia2020.s3.ap-southeast-1.amazonaws.com/upload%2Fweb%2Ffont-icon%2Fmonkey-font-icon.eot")
      format("embedded-opentype"),
    url("https://monkeymedia2020.s3.ap-southeast-1.amazonaws.com/upload%2Fweb%2Ffont-icon%2Fmonkey-font-icon.ttf")
      format("truetype"),
    url("https://monkeymedia2020.s3.ap-southeast-1.amazonaws.com/upload%2Fweb%2Ffont-icon%2Fmonkey-font-icon.woff")
      format("woff"),
    url("https://monkeymedia2020.s3.ap-southeast-1.amazonaws.com/upload%2Fweb%2Ffont-icon%2Fmonkey-font-icon.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Monkey-header";
  src: url("https://hvegjijo7jobj.vcdn.cloud/upload/web/font_web/Quicksand-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  & > iframe {
    pointer-events: none;
  }
  .modal-success .modal-content .btn-access {
    font-size: 14px;
  }
  .modal-active-book {
    .modal-content {
      padding: 30px 0 40px !important;
    }
    .img-wrapper {
      max-width: 55% !important;
    }
    .text-orange {
      color: #ff7707;
    }
    .border-orange {
      border: 1px solid #ff7707;
    }
    .close-modal-active {
      position: relative;
      bottom: 80px;
      right: 10px;
      font-size: 2.5rem;
    }
  }

  .pagination {
    .icon-prev {
      margin-right: 10px;
    }
    .page-link {
      background: transparent;
    }
    .page-link--prev,
    .page-link--next {
      top: -8px;
      right: 8px;
    }
  }
  .result {
    .btn-watch {
      color: #fff;
      background-color: #ff7707;
    }
  }
  // style Game
  .monkey-bg-gray {
    background-color: #4d4c51;
  }
  .monkey-bg-blue {
    background-color: #00c2f3;
  }
  .monkey-color-white {
    color: #fff;
  }
  .monkey-color-white:hover {
    color: black;
  }
  .monkey-fz-20 {
    font-size: 1.25rem;
  }
  .monkey-fz-16 {
    font-size: 1rem;
  }
  .monkey-fz-18 {
    font-size: 1.125rem;
  }
  .monkey-fz-24 {
    font-size: 1.5rem;
  }
  .monkey-fz-25 {
    font-size: 1.67rem;
  }
  .monkey-fz-28 {
    font-size: 1.75rem;
  }
  .monkey-bg-violet {
    background-color: #0066b2;
  }
  .monkey-bg-gray {
    background-color: #4d4c51;
  }
  .monkey-bg-green {
    background-color: #92c83e;
  }
  .monkey-bg-green-2 {
    background-color: #2ecc71 !important;
  }
  .bg-wfd-green {
    background-color: #23bf2d;
  }
  .monkey-bc-transparent {
    border-color: transparent;
  }
  .monkey-bc-blue {
    border-color: #00c2f3;
  }
  .monkey-color-red {
    color: #ee202e;
  }

  .monkey-color-green {
    color: #92c83e;
  }
  .monkey-color-verified {
    color: #07bc0c;
  }
  .result-color-wfd-correct {
    color: #23bf2d;
  }

  .monkey-bg-black {
    background-color: #000;
  }

  .monkey-bg-red {
    background-color: #ee202e;
  }

  .monkey-color-black {
    color: #000;
  }

  .monkey-color-blue {
    color: #00c2f3;
  }
  .monkey-color-yellow {
    color: #ff7707;
  }
  .monkey-bc-red {
    border-color: #ee202e;
  }
  .monkey-bc-green {
    border-color: #92c83e;
  }
  .bc-green-wfd {
    border-color: #23bf2d;
  }
  .monkey-bc-yellow {
    border-color: #ff7707;
  }

  .monkey-fz-25 {
    font-size: 1.67rem;
  }

  // mussic
  .fa-music:before {
    content: "\f001";
  }
  .fa-music.customs-fa-music:after {
    content: "";
    width: 20px;
    height: 3px;
    background-color: #000;
    position: absolute;
    z-index: 88;
    top: 13px;
    left: 4px;
    transform: skewY(45deg);
  }
  .popup_question .modal-body {
    height: 100%;
    padding: 0;
  }
  .popup_question,
  .popup_question .modal-content {
    height: 100%;
    margin: 0 auto;
  }
  .modal.show .modal-dialog {
    transform: none;
  }

  // webkit
  // ::-webkit-scrollbar-thumb {
  //   background-color: #00c2f3;
  //   border-radius: 5px;
  // }
  // ::-webkit-scrollbar {
  //   -webkit-appearance: none;
  //   background-color: #f5f5f5;
  //   width: 10px;
  // }

  // responsive
  @media (min-width: 576px) and (max-width: 1200px) {
    .popup_question {
      max-width: 100%;
    }
  }

  //Style flicker
  .fa-pencil-square-o {
    animation: glowing 1s infinite;
  }

  @keyframes glowing {
    0% {
      background-color: #ec0404;
    }
    50% {
      background-color: #00c2f3;
    }
    100% {
      background-color: #ec0404;
    }
  }
  .reading-book {
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 50%);
    margin: 0 auto;
    cursor: pointer;
  }
  ::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }

  .quicksand-bold {
    font-family: "Quicksand-Bold";
  }
  .quicksand-medium {
    font-family: "Quicksand-Medium";
  }
  @media (min-width: 1025px) {
    .hvr-registration:hover {
      color: #00c2f3;
    }
    .span-wfd-hover:hover {
      color: #ff7707;
    }
  }
  .cursor {
    cursor: pointer;
  }
}

.modal-active-book {
  .modal-content {
    padding: 30px 0 40px !important;
  }
  .img-wrapper {
    max-width: 55% !important;
  }
  .close-modal-active {
    position: relative;
    bottom: 80px;
    right: 10px;
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1024px) {
  #avatar-desktop-screen {
    display: none;
  }
  #navbar-mobile-screen {
    display: none;
  }
  #update-info-mobile-screen {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  header .avatar__name {
    background: none !important;
  }
  #navbar-desktop-screen {
    display: none;
  }
  #active-book-mobi-screen {
    display: none;
  }
}
.form-h10 {
  .input-update {
    height: 50px;
  }
}

.btn-access-active {
  &:hover {
    background: #ff7707 !important;
    opacity: 0.8;
  }
}

.hoc10-guide {
  .total {
    color: #ff7707 !important;
  }
  .result {
    padding: 1rem;
  }
  .link:hover {
    color: #ff7707 !important;
  }
}
.input-active-book::-webkit-input-placeholder {
  font-size: 14px;
}

.monkey-color-violet {
  color: #0066b2;
}

.monkey-f-header {
  font-family: "Monkey-header";
}

.monkey-color-red {
  color: #ee202e;
}

.monkey-color-green {
  color: #92c83e;
}
.modal {
  .modal-dialog {
    align-items: center;
  }
}
.modal-dialog {
  display: flex;
}
.form-forgotpw {
  .img-wrapper {
    min-width: 90% !important;
  }
}
.fsize {
  font-size: 14px;
}
.text-fsize {
  font-size: 20px;
}

.button-color-reference {
  color: #00c2f3;
}
.button-color-reference:hover {
  color: #fff;
}
.button-bg-reference {
  background-color: #fff;
}
.button-bg-reference:hover {
  background-color: #00c2f3;
}
.custom-description-author {
  height: 150px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.btn-read-book {
  padding: 8px 16px;
  margin-top: 40px;
}

.mobile-banner {
  justify-content: space-evenly;
}

#step2 .form-row {
  margin-left: 0;
  margin-right: 0;
  @media (max-width: 668px) {
    .item.flex .d-flex {
      a {
        font-size: 14px !important;
      }
      p {
        font-size: 18px;
      }

      .btn-read-book {
        height: 40px;
        width: 60px;
      }
    }
  }

  @media (max-width: 820px) {
    .item .d-flex {
      a {
        font-size: 18px;
      }

      .btn-read-book {
        height: 50px;
        width: 100px;
      }
    }
  }
}
.disabled-link {
  border: 1px solid #ff7707;
  border-radius: 12px;
  color: var(--orange);
  pointer-events: none;
  background-color: transparent;
}
.content-intro {
  p {
    font-size: 18px;
  }
}
@media (max-width: 669px) {
  .select-school {
    padding-right: 38px;
  }
}

.hoc10-f-header {
  font-family: var(--SVN-GilroyMedium);
  font-weight: 600;
  font-size: 18px;
}

.input-upload-avatar {
  height: 48px;
}

li.liStyles a.disabled {
  color: gray;
  pointer-events: none;
  opacity: 0.3;
}

.list-ask-question {
  list-style: none;
}

.span-number-notification {
  top: 10px;
  left: 10px;
  background-color: red;
  padding: 2px 5px 0;
  border-radius: 30px;
  font-size: 12px;
  color: white;
}

.span-number-notification-mobile {
  right: 10%;
  background-color: red;
  padding: 0 5px;
  border-radius: 30px;
  font-size: 12px;
  color: white;
}
// SEO
.h1Seo {
  h1 {
    &.title {
      margin-bottom: 24px;
      text-align: left;
      color: var(--orange);
      font: 36px/48px var(--SVN-GilroyBold);
      @media (max-width: 668px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
.h5Seo {
  h1 {
    &.title {
      font: 36px/48px var(--SVN-GilroyBold);
      @media (max-width: 668px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
.h4Seo {
  h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
    font-family: var(--SVN-GilroyBold);
    @media (max-width: 668px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

// Question Set
.monkey-bg-question {
  background-color: #ff7707;
}

.monkey-bg-white {
  background-color: #fff;
}

.monkey-bg-gray1 {
  background-color: #eaeced;
}

.monkey-box-shadow {
  box-shadow: 5px 5px 20px 1px #ccc;
}

.hoc10-color-menu-reading-book {
  color: #ddb56a;
}

.hoc10-bg-download {
  color: #ff7707;
  font: 0.8 var(--SVN-GilroyMedium);
}
.monkey-color-orange {
  color: #ff7707;
}

.monkey-bg-orange {
  background-color: #ff7707 !important;
}

.monkey-bg-success {
  background-color: #28a745;
}

.monkey-bg-error {
  background-color: #dc3545;
}

.monkey-color-back-reading-book {
  color: #2a404f;
}

.react-draggable {
  cursor: grab;
}

.react-draggable-dragging {
  cursor: grabbing;
}

.hoc10-list-student {
  padding: 60px 0 100px;
}

.avatar_student {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 24px;
  left: 30px;
}

.question-item:hover {
  .img-orange {
    display: none;
  }
  .img-white {
    display: block;
  }
}
.img-orange {
  display: block;
}
.img-white {
  display: none;
}

.modal-body-custom {
  height: calc(80% - 50px);
}

@media (max-height: 600) {
  .image-small-screen {
    height: 200px;
    width: auto;
  }
}

.fz-24 {
  font-size: 24px;
}
.image-question-exercise {
  max-height: 300px;
  width: auto;
}
.image-question-exercise-full {
  max-height: 100vh;
  max-width: 100vw;
}
@media (max-height: 768px), (max-width: 576px) {
  .image-question-exercise {
    max-height: 200px;
  }
}
.modal-suggestion-dialog {
  height: 100vh;
  margin: 0 auto;
  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
}
.modal-suggestion-content {
  height: 100%;
}
.cursor-move {
  cursor: move;
}
.answerDefault {
  border: 2px solid gray;
}
.answerCorrect {
  border: 2px solid green;
}
.answerIncorrect {
  border: 2px solid red;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ff7707;
  background-color: #ff7707;
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #ff7707;
}
.border-img {
  border: 2px solid #cccccc42;
}
.grid-wrapper-v1 {
  border: 3px solid black;
  border-radius: 10px;
  float: left;
}
.grid-wrapper-v1 table {
  border-collapse: separate;
  border-spacing: 0px;
}
.cell-v1 {
  text-align: center;
  box-sizing: border-box;
  border: 1px solid rgb(20, 198, 244);
}
.cell-v1:hover {
  cursor: pointer;
  background: rgb(20, 198, 244);
}